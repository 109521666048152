<template>
    <div class="detailPage">
        <div class="infoBox">
            <span class="name">{{infos.meetTitle}}</span>
            <span class="line"></span>
            <span class="timeRange" v-if='infos.meetTimeStart'>{{infos.meetTimeStart.split('-').join('/')}}-{{infos.meetTimeEnd.split('-').join('/')}}</span>
            <div class="statusBox">
                <span class="dot"></span>
                <span>{{["未开始", "报名中","报名结束","进行中","培训结束" ][infos.meetStatus]}}</span>
            </div>
        </div>
        <ul class="sections">
            <template v-for="(item,index) in list">
                <li :key='index' @click="goDetailInfo(item.path)">
                    <img :src="item.icon" alt="">
                    <span>{{item.name}}</span>
                </li>
            </template>
        </ul>
        <div class="tips">中国职业技术教育学会</div>
        <Popup v-model="showModal" position="bottom" >
            <div class="popBox">
                <a class="phone" :href="'tel:'+infos.contactUs">{{infos.contactUs}}</a>
                <div class="cancel" @click="showModal=false">取消</div>
            </div>
        </Popup>
    </div>
</template>

<script>
import jsPic from "./../../assets/introduce.png";
import rcPic from "./../../assets/calender.png";
import jtPic from "./../../assets/traffic.png";
import bmPic from "./../../assets/signup.png";
import lxPic from "./../../assets/contact.png";
import {Popup,Dialog,Toast } from "vant";
import{getMeetInfo} from "@/api/meet.js"

    export default {
        name:'detail',
        components:{
            Popup,Toast,
             [Dialog.Component.name]: Dialog.Component,
        },
        data(){
            return{
                id:'',
                list:[
                    {name:'培训介绍',icon:jsPic,path:'meetIntroduce'},
                    {name:'培训日程',icon:rcPic,path:'meetSchedule'},
                    {name:'交通指引',icon:jtPic,path:"meetTraffic"},
                    {name:'报名缴费',icon:bmPic,path:"meetSignUp"},
                    {name:'联系我们',icon:lxPic,path:""},
                ],
                showModal:false,
                infos:{},
            }
        },
        created(){
            this.id=this.$route.query.id
            this.getMeetInfos(this.$route.query.id);
        },
        methods:{
            getMeetInfos(id){
                getMeetInfo({id}).then(res=>{
                    if(res.status=="S"){
                        this.infos=res.data;
                    }
                })
            },
            goDetailInfo(path){
                if(path){
                    if(path=='meetSignUp'){
                        this.$router.push("/meetSignUp?meetId="+this.id)
                    }
                    else{
                     this.$router.push({name:path,params:this.infos})
                    }
                }else{
                    if(this.infos.contactUs){
                        this.showModal=true;
                    }else{
                        Toast('暂无联系电话！');
                    }

                }
            },
        }
    }
</script>

<style lang="less" scoped>
::v-deep.van-popup{
    background-color: transparent;
}

.detailPage{
    position:relative;
    width: 100vw;
    //height: 100vh;
    padding-bottom:0.6rem;
    background-image:url("../../assets/meetBg.png");
    background-size: 103% 103%;
    background-repeat: no-repeat;
    background-position: center center;
    .infoBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:flex-start;
        padding: 0.45rem 0;
        .name{
            padding:0 0.12rem;
            font-size: 18px;
            color: #fff;
            font-weight: bold;
            text-align: center;
        }
        .line{
            display: inline-block;
            width: 0.5rem;
            height: 2px;
            background:rgba(255,255,255,0.5);
            margin:0.22rem 0;
        }
        .timeRange{
            font-size: 14px;
            color: #fff;
        }
        .statusBox{
            //width: 0.8rem;
            padding:0 0.08rem;
            height: 0.25rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border: 1px solid #ffffff;
            border-radius: 3px;
            font-size: 14px;
            color: #fff;
            margin-top: 0.2rem;
            .dot{
                display: inline-block;
                width: 7px;
                height: 7px;
                border-radius: 7px;
                background-color: #0FA84F;
                margin-right: 0.1rem;
            }
        }
    }
    .sections{
        padding:0 0.12rem;
        li{
            width: 100%;
            height: 0.58rem;
            background:#fff;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #333;
            margin-bottom: 0.1rem;
            img{
                width: 17px;
                margin-right: 0.16rem;
            }
        }
    }
    .tips{
        position: absolute;
        text-align: center;
        bottom: 0.1rem;
        left: 0;
        width: 100vw;
        font-size: 14px;
        color: #b0b0b0;
    }
    .popBox{
        background-color: transparent;
        padding:0 0.12rem 0.45rem;
        div,a{
            width: 100%;
            background-color: #fff;
            border-radius: 5px;
            text-align: center;
            color: #2b72ff;
            font-size: 16px;
        }
        .phone{
            display: block;

            height: 0.58rem;
            line-height: 0.58rem;
        }
        .cancel{
            height: 0.41rem;
            line-height: 0.41rem;
            margin-top: 0.1rem;
        }
    }
}
</style>
